<template>
    <v-dialog v-model="isOpen" max-width="800px" persistent>
        <v-card>
            <v-card-title class="px-4 py-4 align-center" style="gap: 8px;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="pt-0"
                    @click.stop="cerrarModal" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
                <h5 class="text-h6 secondary--text text-uppercase font-weight-bold">
                    Ajustar financiamiento
                </h5>
            </v-card-title>
            <v-divider />
            <v-card-text class="mt-4">
                <v-form @submit.prevent="enviarFormulario">
                    <v-text-field 
                        v-model="form.monto"
                        label="Monto a utilizar"
                        outlined
                        type="text"
                        :error-messages="montoErrors"
                        @input="$v.form.monto.$touch()"
                        @blur="$v.form.monto.$touch()"
                    />

                    <div class="d-flex justify-space-between align-center mt-4" style="gap: 16px;">
                        <v-btn
                            large
                            color="secondary" 
                            class="flex-grow-1 flex-shrink-1"
                            @click.stop="cerrarModal"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            large
                            color="primary" 
                            class="flex-grow-1 flex-shrink-1"
                            type="submit"
                        >
                            Guardar
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import { required, decimal, maxValue, minValue } from "vuelidate/lib/validators";
import { Validator } from "@/utils/form-validation";

const dataInicialForm = {
    monto: null,
}

export default {
    name: 'ModalAjustarFinanciamiento',
    emits: ['on-save'],
    components: { MoneyTextFieldComponent },
    validations() {
        return ({
            form: {      
                monto: { 
                    required, 
                    decimal, 
                    maxValue: maxValue(this.montoDisponible),
                    ...this.montoCertificado > 0 && ({ minValue: minValue(this.montoCertificado) }),
                },
            },
        });
    },
    props: {
        isOpen: { type: Boolean },
        montoDisponible: { type: Number },
        montoCertificado: { type: Number },
    },
    data: () => ({
        // UI
        form: { ...dataInicialForm },
    }),
    computed: {
        // Validaciones
        montoErrors() {
            return new Validator(this.$v.form.monto).detect().getResult();
        },
    },
    methods: {
        limpiarCampos() {
            this.form = { ...dataInicialForm };
            this.$v.form.$reset();
        },
        cerrarModal() {
            this.limpiarCampos();
            this.$emit('update:is-open', false);
        },
        enviarFormulario() {
            this.$v.form.$touch();

            if (this.$v.form.$invalid) return;

            this.$emit('on-save', { ...this.form });
            this.cerrarModal();
        },
    },
}
</script>