<template>
    <v-container>
        <v-card>
            <v-card-title style="gap: 8px; line-height: 32px;">
                <NavButton />
                <h3 class="text-h6 font-weight-bold text-uppercase" style="color: var(--v-blueGrayMinsal-darken3); word-break: break-word;">
                    Ajustar refinanciamiento
                </h3>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <p v-if="refinanciamiento.isLoading" class="mb-0 text-body font-weight-bold">Cargando refinanciamiento...</p>
                <dl v-if="!refinanciamiento.isLoading && refinanciamiento.data">
                    <dt class="font-weight-bold text-subtitle-2">Procesos de compras:</dt>
                    <dd class="text-body-2" v-for="proceso in refinanciamiento.data.pcp.pc" :key="proceso.id">{{ proceso.nombre_proceso }} </dd>

                    <dt class="font-weight-bold text-subtitle-2 mt-3">Correlativo de refinanciamiento:</dt>
                    <dd class="text-body-2">{{ refinanciamiento.data.correlativo }}</dd>

                    <dt class="font-weight-bold text-subtitle-2 mt-3">Fecha de creación:</dt>
                    <dd class="text-body-2">{{ formatDate(refinanciamiento.data.created_at) }}</dd>

                    <dt class="font-weight-bold text-subtitle-2 mt-3">Obs:</dt>
                    <dd class="text-body-2">{{ refinanciamiento.data.obs.nombre }}</dd>
                </dl>

                <v-autocomplete
                    v-model="obsSeleccionado"
                    :loading="obs.isLoading"
                    :disabled="cifrados.isLoading || fondos.isLoading"
                    label="OBS"
                    :items="listaObs"
                    outlined
                    item-value="id"
                    item-text="nombre"
                    hide-details
                    clearable
                    class="mt-8"
                    @change="abrirConfirmacionCambioObs"
                />

                <div class="d-flex align-center mt-8" style="gap: 16px;">
                    <v-btn 
                        :disabled="!obsSeleccionado || fondos.isLoading"
                        :loading="cifrados.isLoading"
                        color="primary"
                        @click.stop="modalAgregarFinanciamientoAbierta = true"
                    >
                        <v-icon color="white">mdi-plus</v-icon>
                        Agregar fuente de financiamiento
                    </v-btn>
                </div>

                <DataTableComponent 
                    dense
                    no-gutters
                    :tiene_paginacion="false"
                    :items="fondos.data"
                    :headers="headers"
                    class="mt-4"
                >
                    <template v-slot:item.cifrado_presupuestario="{ item }">
                        {{ item.financiamiento_unidad.fondo.cifrado_presupuestario }}
                        -
                        {{ item.financiamiento_unidad.fondo.tipo_fondo === 2 ? 'votado' : 'proyecto' }}
                    </template>
                    <template v-slot:item.monto_solicitado="{ item }">
                        <b>{{ toMoneyFormat(item.monto_solicitado) }}</b>
                    </template>

                    <template v-slot:item.monto_certificado="{ item }">
                        <b>{{ toMoneyFormat(calcularMontoCertificado(item.certificaciones)) }}</b>
                    </template>
                    
                    <template v-slot:item.accion="{ item }">
                        <div class="d-flex justify-center align-center" style="gap: 8px;">
                            <v-btn icon class="px-5 py-5" @click.stop="ajustarFondoRefinanciamiento(item)">
                                <v-icon color="secondary">mdi-wrench</v-icon>
                            </v-btn>
                            <v-btn v-if="!item.certificaciones.length" icon class="px-5 py-5" @click.stop="confirmarEliminacionFinanciamiento(item)">
                                <v-icon color="secondary">mdi-trash-can</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </DataTableComponent>
            </v-card-text>
        </v-card>

        <ConfirmationModalComponent 
            :is-open="confirmacionCambioObs"
            :is-loading="cambioObs.isLoading"
            title="¿Desea cambiar de OBS?"
            description="Al cambiar de OBS se eliminarán los financiamientos agregados. Esta acción es irreversible."
            @confirm="realizarCambioObs"
            @cancel="cancelarCambioObs"
        />

        <ModalAgregarFinanciamiento 
            :is-open.sync="modalAgregarFinanciamientoAbierta"
            :obs="obsSeleccionado"
            :cifrados="cifrados"
            :financiamientos_agregados="financiamientosAgregados"
            @on-save="manejarGuardadoFinanciamiento"
        />

        <ModalAjustarRefinanciamiento 
            :is-open.sync="modalAjustarFinanciamientoAbierto"
            :monto-disponible="financiamientoDisponible"
            :monto-certificado="montoCertificado"
            @on-save="confirmarAjusteFinanciamiento"
        />

        <ConfirmationModalComponent 
            :is-open="confirmacionEliminadoFinanciamiento"
            :is-loading="eliminadoFinanciamiento.isLoading"
            description="¿Desea eliminar el financiamiento?"
            @confirm="eliminarRefinanciamiento"
            @cancel="confirmacionEliminadoFinanciamiento = false"
        />

        <ConfirmationModalComponent 
            :is-open="confirmacionAjusteFinanciamiento"
            :is-loading="ajusteFinanciamiento.isLoading"
            description="¿Desea ajustar el financiamiento?"
            @confirm="ajustarFinanciamiento"
            @cancel="confirmacionAjusteFinanciamiento = false"
        />

        <ConfirmationModalComponent 
            :is-open="confirmacionAgregarFondo"
            :is-loading="creacionFondo.isLoading"
            description="¿Desea agregar un nuevo fondo al refinanciamiento?"
            @confirm="crearFondo"
            @cancel="confirmacionAgregarFondo = false"
        />
    </v-container>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable, isResponseSuccesful } from '@/utils/loadable';
import ModalAgregarFinanciamiento from './components/ModalAgregarFinanciamiento.vue';
import ModalAjustarRefinanciamiento from './components/ModalAjustarRefinanciamiento.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { NavButton, ConfirmationModalComponent } from '@/components/utils';
import { formatDate } from '@/utils/datetime';
import { toMoneyFormat } from '@/utils/data';
import { capitalize } from 'lodash';

export default {
    name: 'ajustarRefinanciamiento',
    components: { NavButton, DataTableComponent, ModalAgregarFinanciamiento, ConfirmationModalComponent, ModalAjustarRefinanciamiento },
    data: () => ({
        fondoActivo: null,
        obsSeleccionado: null,
        obsPrevio: null,
        idProcesoCompra: null,
        idRefinanciamiento: null,
        modalAgregarFinanciamientoAbierta: false,
        confirmacionCambioObs: false,
        confirmacionEliminadoFinanciamiento: false,
        // Ajuste financiamiento
        modalAjustarFinanciamientoAbierto: false,
        confirmacionAjusteFinanciamiento: false,
        informacionAjusteFinanciamiento: null,
        // Data
        cifrados: createLoadable([]),
        obs: createLoadable([]),
        refinanciamiento: createLoadable(null),
        fondos: createLoadable([]),
        ajusteFinanciamiento: createLoadable(null),
        eliminadoFinanciamiento: createLoadable(null),
        cambioObs: createLoadable(null),
        // Agregar nuevo cifrado
        nuevoFondoCache: null,
        confirmacionAgregarFondo: false,
        creacionFondo: createLoadable(null),
    }),
    computed: {
        listaObs() {
            return this.obs.data.map((item) => ({
                id: item.id,
                nombre: `${item.codigo} - ${capitalize(item.nombre)}`,
            }));
        },
        headers() {
            return [
                { text: 'Código presupuestario', value: 'cifrado_presupuestario', align: 'center', sortable: false },
                { text: 'Monto ($)', value: 'monto_solicitado', align: 'center', sortable: false },
                { text: 'Monto certificado ($)', value: 'monto_certificado', align: 'center', sortable: false },
                { text: 'Acción', value: 'accion', align: 'center', sortable: false },
            ];
        },
        financiamientoDisponible() {
            return Number(this.fondoActivo?.financiamiento_unidad.monto_disponible ?? 0);
        },
        montoCertificado() {
            if (!this.fondoActivo) return 0;

            return this.calcularMontoCertificado(this.fondoActivo.certificaciones);
        },
        financiamientosAgregados() {
            return this.fondos.data.map((fondo) => fondo.financiamiento_unidad.id);
        },
    },
    methods: {
        // UI
        formatDate,
        toMoneyFormat,
        abrirConfirmacionCambioObs() {
            this.confirmacionCambioObs = true;
        },
        cancelarCambioObs() {
            this.confirmacionCambioObs = false;
            this.obsSeleccionado = this.obsPrevio;
        },
        manejarGuardadoFinanciamiento(financiamiento) {
            this.confirmacionAgregarFondo = true;
            this.nuevoFondoCache = { ...financiamiento };
        },
        resetearInformacion() {
            this.refinanciamiento = createLoadable(null);
            this.obsSeleccionado = null;
            this.obsPrevio = null;
            this.fondos = createLoadable([]);
            this.cifrados = createLoadable([]);

            this.cargarRefinanciamiento();
            this.cargarFondosRefinanciamiento();
        },
        calcularMontoCertificado(certificaciones) {
            return certificaciones.reduce((prev, certificacion) => prev + Number(certificacion.monto), 0);
        },
        // Eliminacion OBS
        confirmarEliminacionFinanciamiento(financiamiento) {
            this.fondoActivo = financiamiento;
            this.confirmacionEliminadoFinanciamiento = true;
        },
        // Ajuste fondo
        ajustarFondoRefinanciamiento(fondo) {
            this.fondoActivo = { ...fondo };
            this.modalAjustarFinanciamientoAbierto = true;
        },
        confirmarAjusteFinanciamiento(informacion) {
            this.informacionAjusteFinanciamiento = { ...informacion };
            this.confirmacionAjusteFinanciamiento = true;
        },
        // Data
        async cargarCifrados() {
            if (!this.obsSeleccionado) {
                this.cifrados = createLoadable([]);
                return;
            }

            try {
                toggleLoadable(this.cifrados);
                const { data } = await this.services.RefinanciamientoCompras.getCifradosOBS(this.obsSeleccionado, { tipo_fondo: "1,2" });
                setLoadableResponse(this.cifrados, { data });
            } catch (error) {
                this.cifrados.data = [];
                this.cifrados.isLoading = false;
            }
        },
        async cargarCatalogoObs() {
            toggleLoadable(this.obs);
            try {
                const { data } = await this.services.PacServices.getOBSSegmentos({ pagination: false });
                setLoadableResponse(this.obs, { data });
            } catch (error) {
                this.obs.isLoading = false;
                this.obs.data = [];
            }
        },
        async cargarRefinanciamiento() {
            toggleLoadable(this.refinanciamiento);
            const { data } = await this.services.RefinanciamientoCompras.buscarRefinanciamiento(this.idProcesoCompra, this.idRefinanciamiento);
            setLoadableResponse(this.refinanciamiento, data);

            if (isResponseSuccesful(data)) {
                this.obsSeleccionado = data.data.obs.id;
                this.cargarCifrados();
            }
        },
        async cargarFondosRefinanciamiento() {
            toggleLoadable(this.fondos);
            const { data } = await this.services.RefinanciamientoCompras.listarFondosPorRefinanciamiento(this.idProcesoCompra, this.idRefinanciamiento);
            setLoadableResponse(this.fondos, data);
        },
        async ajustarFinanciamiento() {
            toggleLoadable(this.ajusteFinanciamiento);
            const { data } = await this.services.RefinanciamientoCompras.ajustarFondoRefinanciamiento(
                this.idProcesoCompra, 
                this.fondoActivo.id, 
                this.informacionAjusteFinanciamiento
            );
            this.confirmacionAjusteFinanciamiento = false;

            setLoadableResponse(this.ajusteFinanciamiento, data, { skipOnSuccess: true, showAlertOnSuccess: true })

            
            if (isResponseSuccesful(data)) this.resetearInformacion();

            if (!isResponseSuccesful(data) && data.error.code ==='FONDO_CON_AJUSTE_EXISTENTE') this.resetearInformacion();
        },
        async eliminarRefinanciamiento() {
            toggleLoadable(this.eliminadoFinanciamiento);
            const { data } = await this.services.RefinanciamientoCompras.eliminarFondoRefinanciamiento(this.idProcesoCompra, this.fondoActivo.id);
            this.confirmacionEliminadoFinanciamiento = false;
            
            setLoadableResponse(this.eliminadoFinanciamiento, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (isResponseSuccesful(data)) this.resetearInformacion();
        },
        async realizarCambioObs() {
            toggleLoadable(this.cambioObs);
            const { data } = await this.services.RefinanciamientoCompras.cambiarObsRefinanciamiento(
                this.idProcesoCompra, 
                this.idRefinanciamiento,
                { obs: this.obsSeleccionado }
            );
            this.confirmacionCambioObs = false;

            setLoadableResponse(this.cambioObs, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.resetearInformacion();
        },
        async crearFondo() {
            const payload = {
                monto: this.nuevoFondoCache.monto,
                id_financiamiento_unidad: this.nuevoFondoCache.financiamiento.id,
            }

            toggleLoadable(this.creacionFondo);
            const { data } = await this.services.RefinanciamientoCompras.agregarFondoRefinanciamiento(
                this.idProcesoCompra,
                this.idRefinanciamiento,
                payload,
            );

            this.confirmacionAgregarFondo = false;

            setLoadableResponse(this.creacionFondo, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarFondosRefinanciamiento();
        },
    },
    watch: {
        obsSeleccionado: {
            handler(_, valorPrevio) {
                this.obsPrevio = valorPrevio;
                this.idProcesoCompra = this.$route.params.id_proceso_compra;
            },
        },
    },
    created() {
        this.idProcesoCompra = this.$route.params.id_proceso_compra;
        this.idRefinanciamiento = this.$route.params.id_refinanciamiento;
        this.cargarRefinanciamiento();
        this.cargarCatalogoObs();
        this.cargarFondosRefinanciamiento();
    },
}
</script>